'use client';
import { selectAuthState } from '@/store/authSlice';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Company = ({ params }) => {
  const company = params.company;
  const auth = useSelector(selectAuthState);
  const token = auth?.loginDetails?.token;
  const router = useRouter();

  useEffect(() => {
    // Ensure this runs on the client side
    if (typeof window !== 'undefined') {
      if (company) {
        if (token) {
          router.push(`/${company}/job-management/`);
        } else {
          router.push(`/${company}/login/`);
        }
      } else {
        router.push('/');
      }
    }
  }, [company, router, token]);

  return (
    <div>
      {/* Placeholder content */}
      <p>Loading... {company} Screen</p>
    </div>
  );
};

export default Company;
